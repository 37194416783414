import React from "react";
import Hero from "./hero/Hero";
import AboutBlock from "./aboutBlock/AboutBlock";

const Home = () => {

  return (
    <>
      <Hero />
      <AboutBlock />
    </>
  );
};

export default Home;
