import React from "react";
import styles from "./AboutBlock.module.css";
import Divider from "@mui/material/Divider";

const AboutBlock = () => {
  return (
    <section className={styles.blocksContainer}>
      <h1>Our Services</h1>
      <ul className={styles.listContainer}>
        <li className={styles.listItem}>
          <img src="../images/CarpenterHome/painting.png" alt="" />
          <div>RESIDENTIAL<br/>RENOVATION</div>
        </li>
        <Divider
          orientation="horizontal"
          variant="middle"
          flexItem
          sx={{ borderRightWidth: 3, borderRadius: 2 }}
        />
        <li className={styles.listItem}>
          <i className="ri-pencil-ruler-2-line"></i>
          <div>DECKING</div>
        </li>
        <Divider
          orientation="horizontal"
          variant="middle"
          flexItem
          sx={{ borderRightWidth: 3, borderRadius: 2 }}
        />
        <li className={styles.listItem}>
          <svg fill="#989797" width="100px" height="100px" viewBox="0 0 15 15" id="fence" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M13.5,10H13V7h.5a.5.5,0,0,0,0-1H13V4l-.286-.573a.249.249,0,0,0-.424-.006L12,4V6H11V4l-.286-.573a.249.249,0,0,0-.424-.006L10,4V6H9V4l-.286-.573a.249.249,0,0,0-.424-.006L8,4V6H7V4l-.286-.573a.249.249,0,0,0-.424-.006L6,4V6H5V4l-.286-.573a.249.249,0,0,0-.424-.006L4,4V6H3V4l-.286-.573a.249.249,0,0,0-.424-.006L2,4V6H1.5a.5.5,0,0,0,0,1H2v3H1.5a.5.5,0,0,0,0,1H2v1.5a.5.5,0,0,0,1,0V11H4v1.5a.5.5,0,0,0,1,0V11H6v1.5a.5.5,0,0,0,1,0V11H8v1.5a.5.5,0,0,0,1,0V11h1v1.5a.5.5,0,0,0,1,0V11h1v1.5a.5.5,0,0,0,1,0V11h.5a.5.5,0,0,0,0-1ZM3,10V7H4v3Zm2,0V7H6v3Zm2,0V7H8v3Zm2,0V7h1v3Zm2,0V7h1v3Z"></path> </g></svg>
          <div>FENCING</div>
        </li>
        <Divider
          orientation="horizontal"
          variant="middle"
          flexItem
          sx={{ borderRightWidth: 3, borderRadius: 2 }}
        />
        <li className={styles.listItem}>
        <img src="../images/CarpenterHome/building.png" alt="" />
          <div>COMMERCIAL<br/>RENOVATION</div>
        </li>
      </ul>
    </section>
  );
};

export default AboutBlock;
