import React, { useState } from "react";
import Homco from "../../images/Logoshop.png";
import HomcoInvertColor from "../../images/InvertLogo.png";
import styles from "./HeaderTransparent.module.css";
import { Link } from "react-router-dom";

const HeaderTransparent = () => {
  const [navList, setNavList] = useState(false);
  const [navbarScroll, setNavbarScroll] = useState(false);
  const changeNavbarBackground = () => {
    if (window.scrollY >= 80) {
      setNavbarScroll(true);
    } else {
      setNavbarScroll(false);
    }
  };

  window.addEventListener("scroll", changeNavbarBackground);

  return (
    <nav
      className={
        navbarScroll
          ? `${styles.headerTransparent} ${styles.active}`
          : styles.headerTransparent
      }
    >
      <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
        <div
          className={`${styles.logoContainer} ${styles.logoContainerMobile}`}
        >
          <img className={styles.logo} src={Homco} alt="" />
        </div>
      </Link>
      <ul className={styles.navMenu}>
        <li>
          <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
            <div className={styles.logoContainer}>
              <img className={styles.logo} src={Homco} alt="" />
            </div>
          </Link>
        </li>
        <li>
          <Link to={"/portfolio"} onClick={() => window.scrollTo(0, 0)}>
            PORTFOLIO
          </Link>
        </li>
        <li>
          <Link to={"/about"} onClick={() => window.scrollTo(0, 0)}>
            ABOUT US
          </Link>
        </li>
        <li>
          <Link to={"/contact"} onClick={() => window.scrollTo(0, 0)}>
            CONTACT US
          </Link>
        </li>
      </ul>
      <button
        className={`${styles.toggleButton} ${
          navList && styles["mobilePosition"]
        }`}
        onClick={() => setNavList(!navList)}
      >
        {navList ? (
          <i className="ri-close-line"></i>
        ) : (
          <i className="ri-menu-line"></i>
        )}
      </button>
      {navList && (
        <div className={styles.mobileHeader}>
          <ul className={styles.mobileNavMenu}>
            <li onClick={() => setNavList(!navList)}>
              <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
                HOME
              </Link>
            </li>
            <li onClick={() => setNavList(!navList)}>
              <Link to={"/portfolio"} onClick={() => window.scrollTo(0, 0)}>
                PORTFOLIO
              </Link>
            </li>
            <li onClick={() => setNavList(!navList)}>
              <Link to={"/about"} onClick={() => window.scrollTo(0, 0)}>
                ABOUT US
              </Link>
            </li>
            <li onClick={() => setNavList(!navList)}>
              <Link to={"/contact"} onClick={() => window.scrollTo(0, 0)}>
                CONTACT US
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default HeaderTransparent;
