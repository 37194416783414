import React, { useState, useEffect } from "react";
import styles from "./AboutSubBlock.module.css";

const AboutSubBlock = (props) => {
  const [imagePosition, setImagePosition] = useState(props.imagePosition);
  function widthResizer() {
    var width = window.innerWidth;
    if (width <= 800) {
      setImagePosition("right");
    } else {
      setImagePosition(props.imagePosition);
    }
  }
  window.addEventListener("resize", widthResizer);
  
  useEffect(() => {
    const width = window.innerWidth;
    if (width <= 800) {
      setImagePosition("right");
    } else {
      setImagePosition(props.imagePosition);
    }
  }, []);
  return (
    <div className={styles.container}>
      {imagePosition === "left" && (
        <div className={`${styles.imageBlock} ${styles.imageBlockLeft}`}>
          <img src={props.content.imgSrc} alt="" />
        </div>
      )}
      <div className={styles.textBlock}>
        <h1>{props.content.title}</h1>
        <div className={styles.textDescription}>
          {props.content.description}
        </div>
      </div>
      {imagePosition === "right" && (
        <div className={`${styles.imageBlock} ${styles.imageBlockRight}`}>
          <img src={props.content.imgSrc} alt="" />
        </div>
      )}
    </div>
  );
};

export default AboutSubBlock;
