import React, { useState, useEffect } from "react";
import styles from "./ImageSlide.module.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  pauseOnHover: true,
  autoplay: false,
  indicators: false,
  onChange: (oldIndex, newIndex) => {
    // console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  },
};

const ImageSlider = ({ slideImages, defaultIndex }) => {
  return (
    <section className={styles.imageSlideContainer}>
      <div className={styles.slideContainer}>
        <Slide {...properties} defaultIndex={defaultIndex}>
          {slideImages.map((slideImage, index) => (
            <div className={styles.eachSlide} key={index}>
              <img src={slideImage} alt="" />
            </div>
          ))}
        </Slide>
      </div>
    </section>
  );
};

export default ImageSlider;
