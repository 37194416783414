import React, { useState, useEffect } from "react";
import styles from "./GalleryChild.module.css";
import ImageSlide from "./ImageSlide";

const GalleryChild = ({ mainLeft, imgSrc, isParallel, slideImages, groupNumber }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageNumber, setImageNumber] = useState(0);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  document.onkeydown = function(event) {
    if (event.keyCode === 27) {
      setIsModalOpen(false);
    }
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className={ !isParallel ? styles.galleryChild : `${styles.galleryChild} ${styles.parallelGalleryChild}`}>
      <div
        className={`${styles.imageContainer} ${
          mainLeft ? styles.galleryItem1Left : styles.galleryItem1Right
        } `}
      >
        <img src={imgSrc[0]} alt="" />
        {(
          <div
            className={styles.imageCover}
            onClick={() => {
              setIsModalOpen(true);
              setImageNumber(0);
            }}
          />
        )}
      </div>
      <div
        className={`${styles.imageContainer} ${
          mainLeft ? styles.galleryItem2Left : styles.galleryItem2Right
        } `}
      >
        <img src={imgSrc[1]} alt="" />
        {(
          <div
            className={styles.imageCover}
            onClick={() => {
              setIsModalOpen(true);
              setImageNumber(1);
            }}
          />
        )}
      </div>
      <div
        className={`${styles.imageContainer} ${
          mainLeft ? styles.galleryItem3Left : styles.galleryItem3Right
        } `}
      >
        <img src={imgSrc[2]} alt="" />
        {(
          <div
            className={styles.imageCover}
            onClick={() => {
              setIsModalOpen(true);
              setImageNumber(2);
            }}
          />
        )}
      </div>
      {isModalOpen && (
        <div
          className={isModalOpen && styles.modalContainer}
        >
          <i className="ri-close-line" 
          onClick={() => {
            setIsModalOpen(false);
          }} />
          <div className={styles.slideContainer}>
            <ImageSlide slideImages={slideImages} defaultIndex={ (groupNumber + imageNumber) } />
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryChild;
