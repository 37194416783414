import React, { useState, useEffect } from "react";
import HeadingBlock from "../../common/headingBlock/HeadingBlock";
import AboutSubBlock from "./subBlocks/AboutSubBlock";
import { aboutContent } from "./aboutContent"

const About = () => {
  return (
    <>
      <HeadingBlock
        title="CONSTRUCTION SERVICE"
        backgroundImg="../images/CarpenterHeading/about.JPG"
      />
      <AboutSubBlock imagePosition={"left"} content={aboutContent.one}/>
      <AboutSubBlock imagePosition={"right"} content={aboutContent.two} />
      <AboutSubBlock imagePosition={"left"} content={aboutContent.three} />
      <div style={{height: "8rem"}}></div>
    </>
  );
};

export default About;
