import React from "react";
import { Paper } from "@mui/material";
import styles from "./Disclaimer.module.css";
import { styled } from "@mui/material/styles";

const Disclaimer = () => {
  const Item = styled(Paper)(() => ({
    textAlign: "left",
    // height: "fit-content",
    padding: "2rem",
    fontSize: "30px",
    fontWeight: "400",
    // lineHeight: "20px",
  }));

  return (
    <div className={styles.disclaimer}>
      <Item>
        <div className={styles.title}>{"Disclaimer"}</div>
        <br></br>
        {
          "Please read this disclaimer carefully before using website operated by JackLee Construction Company. The content displayed on the website is the intellectual property of the JackLee Construction Company. You may not reuse, republish, or reprint such content without our written consent. All information posted is merely for educational and informational purposes. It is not intended as a substitute for professional advice. Should you decide to act upon any information on this website, vou do so at your own risk. While the information on this website has been verified to the best of our abilities, we cannot guarantee that there are no mistakes or errors. We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page."
        }
      </Item>
    </div>
  );
};

export default Disclaimer;
