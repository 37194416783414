import React from "react";
import styles from "./HeadingBlock.module.css";

const HeadingBlock = ({ title, backgroundImg }) => {
  return (
    <div
      className={styles.heading}
      style={
        backgroundImg && {
          background: `linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) ), url(${backgroundImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }
      }
    >
      <div className={styles.titleBox}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};

export default HeadingBlock;
