import React from "react";
import styles from "./Hero.module.css";
import backgroundVideo from "../../../videos/carpenterHero.mp4";

import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className={styles.hero}>
      <video
        autoPlay
        loop
        muted
        playsInline
        className={styles.backVideo}
        id={"playVideo"}
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className={styles.content}>
        <h1>JACKLEE</h1>
        <h4>Build Greatness</h4>
      </div>
    </div>
  );
};

export default Hero;
