export const aboutContent = {
  one: {
    title: "WHO WE ARE",
    description:
      "JackLee Construction is a full-service construction management company with extensive residential renovation, decking, fencing and commercial renovations completed throughout Nova Scotia.",
    imgSrc: "../images/CarpenterAbout/p-1.JPG",
  },
  two: {
    title: "OUR PROCESS",
    description:
      "Beginning at the point of the project's inception, we work with our clients to develop preliminary budgets for use in the project planning. Once the project is past the planning stage we value engineer the project and come back with competitive pricing. Quality, affordability and best services are our motto.",
    imgSrc: "../images/CarpenterAbout/p-2.jpg",
  },
  three: {
    title: "WHY CHOOSE US",
    description:
      "We believe that working as a team with the architect and owner is the most successful and cost effective way to ensure that out clients reach their goals.",
    imgSrc: "../images/CarpenterAbout/p-3.JPG",
  },
};
