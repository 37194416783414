import React, { useState, useEffect } from "react";
import styles from "./Form.module.css";

const Form = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div>
      <form
        action="https://getform.io/f/5c2aec57-1997-4344-b754-c59c0b5528bf"
        method="POST"
        className={styles.form}
      >
        <div className={styles.column}>
          <div className={styles.inputBox}>
            <label>FIRST NAME </label>
            <label className={styles.inputLabel}>*</label>
            <input
              type="text"
              name="firstname"
              placeholder=""
              required
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              value={firstName}
            />
          </div>
          <div className={styles.inputBox}>
            <label>LAST NAME </label>
            <label className={styles.inputLabel}>*</label>
            <input
              type="text"
              name="lastname"
              placeholder=""
              required
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              value={lastName}
            />
          </div>
        </div>

        <div className={styles.inputBox}>
          <label>EMAIL </label>
          <label className={styles.inputLabel}>*</label>
          <input
            type="text"
            name="email"
            placeholder=""
            required
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            value={email}
          />
        </div>

        <div className={`${styles.inputBox}`}>
          <label>MESSAGE </label>
          <label className={styles.inputLabel}>*</label>
          <textarea
            className={styles.textArea}
            name="message"
            placeholder=""
            required
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            value={message}
          />
        </div>
        <button type="submit">SUBMIT</button>
      </form>
    </div>
  );
};

export default Form;
