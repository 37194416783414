import React from "react";
import HeaderTransparent from "../common/header/HeaderTransparent";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./homePage/Home";
import About from "./aboutPage/About";
import Projects from "./projectsPage/Projects";
import Connect from "./connectPage/Connect";
import Footer from "../common/footer/Footer";
import Disclaimer from "../common/disclaimer/Disclaimer";

const Pages = () => {
  return (
    <>
      <Router>
        <HeaderTransparent />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route exact path="/portfolio" component={Projects} />
          <Route path="/contact" component={Connect} />
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="*" component={Home} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default Pages;
