import React, { useState, useEffect } from "react";
import styles from "./Connect.module.css";
import Form from "./subBlocks/Form";
import HeadingBlock from "../../common/headingBlock/HeadingBlock";

const Connect = () => {
  return (
    <div>
      <HeadingBlock
        title="CONTACT US"
        backgroundImg="../images/CarpenterHeading/contactus.png"
      />
      <div className={styles.subBlockOne}>
        <div className={styles.infoContainer}>
          <h1>OUR INFORMATION</h1>
          <h4>PHONE:</h4>
          <div>+1 (902)-301-8899</div>
          <h4>EMAIL:</h4>
          <div>JACK@JACKLEECONSTRUCTION.CA</div>
          <h4>ADDRESS:</h4>
          <div>HALIFAX, NS, Canada</div>
        </div>
        <div className={styles.formContainer}>
          <h1>GENERAL INQUIRIES</h1>
          <Form />
        </div>
      </div>
    </div>
  );
};

export default Connect;
