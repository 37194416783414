import React, { useState, useEffect } from "react";
import HeadingBlock from "../../common/headingBlock/HeadingBlock";
import Gallery from "./subBlocks/Gallery";

const Projects = () => {

  const gallery = [
    "../images/CarpenterGallery/p-1.png",
    "../images/CarpenterGallery/p-2.png",
    "../images/CarpenterGallery/p-3.png",
    "../images/CarpenterGallery/p-4.png",
    "../images/CarpenterGallery/p-5.png",
    "../images/CarpenterGallery/p-6.png",
    "../images/CarpenterGallery/p-7.png",
    "../images/CarpenterGallery/p-8.png",
    "../images/CarpenterGallery/p-9.png",
    "../images/CarpenterGallery/p-10.png",
    "../images/CarpenterGallery/p-11.png",
    "../images/CarpenterGallery/p-12.png",
    ]
  return (
    <>
      <HeadingBlock
        title="CONSTRUCTION PORTFOLIO"
        backgroundImg="../images/CarpenterHeading/portfolio-1.JPG"
      />
      <Gallery galleryImageUrls={gallery}/>
    </>
  );
};

export default Projects;
