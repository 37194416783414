import React, { useEffect, useState } from "react";
import GalleryChild from "./GalleryChild";
import styles from "./Gallery.module.css";

const Gallery = ({galleryImageUrls}) => {
  return (
    <div className={styles.gallery}>
      <h4>GALLERY</h4>
      <GalleryChild mainLeft={true} slideImages={galleryImageUrls} groupNumber={0} imgSrc={galleryImageUrls?.slice(0,3)} />
      <GalleryChild mainLeft={true} slideImages={galleryImageUrls} groupNumber={3} imgSrc={galleryImageUrls?.slice(3,6)} isParallel />
      <GalleryChild mainLeft={false} slideImages={galleryImageUrls} groupNumber={6} imgSrc={galleryImageUrls?.slice(6,9)} />
      <GalleryChild mainLeft={true} slideImages={galleryImageUrls} groupNumber={9} imgSrc={galleryImageUrls?.slice(9,12)} isParallel />
    </div>
  );
};

export default Gallery;
