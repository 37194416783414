import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import HomcoInvertColor from "../../images/InvertLogo.png";

const Footer = () => {
  return (
    <>
      <footer>
        <div className={styles.footerContainer}>
          <div className={styles.footerBox}>
            <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
              <img className={styles.logo} src={HomcoInvertColor} alt="" />
            </Link>
            <ul className={styles.boxLists}>
              <li className={styles.boxItems}>
                <div className={styles.iconItems}>
                  <i className="ri-facebook-box-fill"></i>
                  {/* <i className="ri-twitter-fill"></i> */}
                  {/* <a
                    href="https://www.instagram.com/"
                    target="_blank"
                  > */}
                  <i className="ri-instagram-fill"></i>
                  {/* </a> */}
                  <i className="ri-linkedin-box-fill"></i>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.footerBox}>
            <Link to={"/portfolio"} onClick={() => window.scrollTo(0, 0)}>
              PORTFOLIO
            </Link>
          </div>
          <div className={styles.footerBox}>
            <Link to={"/about"} onClick={() => window.scrollTo(0, 0)}>
              ABOUT US
            </Link>
          </div>
          <div className={styles.footerBox}>
            <Link to={"/contact"} onClick={() => window.scrollTo(0, 0)}>
              CONTACT US
            </Link>
          </div>
        </div>

        <hr className={styles.breakLine} />
        <div className={styles.legal}>
          {/* <span>© 2021 HOMCO. Designd By Siqi.</span> */}
          <ul className={styles.legalList}>
            <li className={styles.legalFirstItem}>
              ©2023 JackLee Construction Services
            </li>
            <li className={styles.legalItem}>
              <Link to={"/disclaimer"} onClick={() => window.scrollTo(0, 0)}>
                DISCLAIMER
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
